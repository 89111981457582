import React from 'react';
import Map, { Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MurmurHash3 from 'imurmurhash';

import { RingSpinnerOverlay } from 'react-spinner-overlay';
import Scale from './scale'
import { getTransformationsMapForContext } from './transformer';

export const NO_SELECTION_MODE = 0;
export const POINT_SELECTION_MODE = 1;
export const SECTOR_SELECTION_MODE = 2;
export const SEGMENT_SELECTION_MODE = 3;

const ROUTE_COLORS = ["#8dd3c7",
    "#ffffb3",
    "#bebada",
    "#fb8072",
    "#80b1d3",
    "#fdb462",
    "#b3de69",
    "#fccde5",
    "#d9d9d9",
    "#bc80bd",
    "#ccebc5",
    "#ffed6f"];

class AccessMap extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            projection: undefined,
        }

        this.refreshBoundaries = (e) => {
            if (this.props.boundaryFindingMode && (e.originalEvent === undefined || e.originalEvent.type !== "resize")) {
                const bounds = e.target.getBounds();
                const zoomLevel = e.target.getZoom();

                if (zoomLevel > 10) {
                    const west = bounds.getWest();
                    const south = bounds.getSouth();
                    const east = bounds.getEast();
                    const north = bounds.getNorth();
                    this.props.setBoundaries(west, south, east, north);
                } else {
                    this.props.clearBoundaries();
                }
            }
        }

        this.moveMap = (e) => {
            this.props.setViewport(e.viewState);
        }

        this.handleClick = (e) => {
            if (this.props.selectionMode === POINT_SELECTION_MODE) {
                this.handlePointClick(e)
            } else if (this.props.selectionMode === SECTOR_SELECTION_MODE) {
                this.handleSectorClick(e)
            } else if (this.props.selectionMode === SEGMENT_SELECTION_MODE) {
                const segmentsMap = e.features
                    .filter(feature => feature.layer.id === "segment-journeys-layer")
                    .reduce((accumulator, feature) => {
                        accumulator[feature.properties.routingId] = {
                            "routingId": feature.properties.routingId,
                            "sequence": feature.properties.sequence,
                            "label": feature.properties.routes,
                            "color": feature.properties.color,
                            "value": feature.properties.value,
                            "routingBeginningStop": feature.properties.routingBeginningStop,
                            "routingEndStop": feature.properties.routingEndStop,
                            "routingStopCount": feature.properties.routingStopCount,
                        };
                        return accumulator;
                    }, {});
                const segmentRecord = {
                    "point": e.lngLat,
                    "segments": Object.values(segmentsMap),
                }
                this.props.selectSegment(segmentRecord)
            }
        }

        this.handleHover = (e) => {
            if (this.props.selectionMode === SEGMENT_SELECTION_MODE) {
                const segmentsMap = e.features
                    .filter(feature => feature.layer.id === "segment-journeys-layer")
                    .reduce((accumulator, feature) => {
                        accumulator[feature.properties.routingId] = {
                            "routingId": feature.properties.routingId,
                            "sequence": feature.properties.sequence,
                            "label": feature.properties.routes,
                            "color": feature.properties.color,
                            "value": feature.properties.value,
                            "routingBeginningStop": feature.properties.routingBeginningStop,
                            "routingEndStop": feature.properties.routingEndStop,
                            "routingStopCount": feature.properties.routingStopCount,
                        };
                        return accumulator;
                    }, {});
                const segmentRecord = {
                    "point": e.lngLat,
                    "segments": Object.values(segmentsMap),
                }
                this.props.hoverSegment(segmentRecord)
            }
        }

        this.handlePointClick = (e) => {
            const coord = this.makePointString(e.lngLat)
            this.props.setClickedLocation(coord);
        }

        this.handleSectorClick = (event) => {
            if (event && event.features
                && event.features.length > 0
                && event.features[0].properties
                && event.features[0].properties.sectorId !== undefined) {

                const sectorId = event.features[0].properties.sectorId + 1;

                if (this.props.isExhaustive
                    && sectorId === this.props.expandedSector) {
                    this.props.clearExpandedSector();
                } else if (this.props.isExhaustive
                    && sectorId === this.props.selectedSector) {
                    this.props.setExpandedSector(sectorId);
                } else {
                    this.props.setSector(sectorId);
                }
            }
        }

        this.parsePointString = (pointString) => {
            const coordStrings = pointString.split(",");
            const lat = parseFloat(coordStrings[0].trim());
            const lon = parseFloat(coordStrings[1].trim());

            return [lon, lat]
        }

        this.makePointString = (lonLat) => {
            return `${lonLat.lat.toFixed(5)}, ${lonLat.lng.toFixed(5)}`
        }

        this.toCoordinate = (sector) => {
            const coordStrings = sector.bounds.split(",");
            const west = parseFloat(coordStrings[0]);
            const south = parseFloat(coordStrings[1]);
            const east = parseFloat(coordStrings[2]);
            const north = parseFloat(coordStrings[3]);


            const p1 = [west, north];
            const p2 = [west, south];
            const p3 = [east, south];
            const p4 = [east, north];

            return [p1, p2, p3, p4, p1]
        }

        this.toSectorFeature = (sector, colorMapping) => {
            return {
                "type": "Feature",
                "properties": {
                    "sectorId": sector.id,
                    "description": "sector: " + (sector.id + 1) + "\nvalue: " + sector.value.toFixed(4) + "\nrank: " + (sector.rank + 1),
                    "color": colorMapping.toColor(sector.value),
                    'type': 'fill',
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [this.toCoordinate(sector)]
                }
            }
        }

        this.toSegmentFeature = (segmentRecord, colorMapping, selectedSegment) => {
            const points = segmentRecord.points;
            const value = segmentRecord.value;
            const featurePoints = points.map(point => {
                return [parseFloat(point.longitude), parseFloat(point.latitude)];
            });

            let color;
            if (selectedSegment) {
                if (segmentRecord.routingId === selectedSegment.routingId &&
                    segmentRecord.sequence === selectedSegment.sequence) {
                    color = "#333333"
                } else {
                    color = "#cccccc"
                }
            } else {
                color = colorMapping.toColor(value);
            }

            const linestringFeature = {
                "type": "Feature",
                "properties": {
                    "routingId": segmentRecord.routingId,
                    "sequence": segmentRecord.sequence,
                    "color": color,
                    "routes": segmentRecord.routes.join("/"),
                    "routingBeginningStop": segmentRecord.routingBeginningStop,
                    "routingEndStop": segmentRecord.routingEndStop,
                    "routingStopCount": segmentRecord.routingStopCount,
                    "value": value
                },
                "geometry": {
                    "type": "LineString",
                    "coordinates": featurePoints
                }
            }
            return linestringFeature;
        }

        this.toEmptySectorFeature = (sector) => {
            return {
                "type": "Feature",
                "properties": {
                    "sectorId": sector.id,
                    "color": "#ffffff",
                    'type': 'fill',
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [this.toCoordinate(sector)]
                }
            }
        }

        this.toOutlineFeature = (sector) => {
            return {
                "type": "Feature",
                "properties": {
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [this.toCoordinate(sector)]
                }
            }
        }
    }


    render() {
        let sectorValues;
        let segmentValues;
        let colorMapping;
        let isochroneFeatures;
        let segmentFeatures;


        if (this.props.transformationType !== undefined) {
            let transformedItems;
            if (this.props.segmentTransformations !== undefined) {
                if (this.props.selectedSegment !== undefined) {
                    transformedItems = this.props.sectorTransformations.get(
                        this.props.transformationType);
                    sectorValues = transformedItems.values;
                    colorMapping = this.props.colorScheme.getColorMapping(
                        transformedItems.min, transformedItems.max, 10);
                    isochroneFeatures = sectorValues.map((sector) => {
                        return this.toSectorFeature(sector, colorMapping);
                    });
                    transformedItems = this.props.segmentTransformations.first();
                } else {
                    sectorValues = this.props.sectors;
                    isochroneFeatures = [];
                    transformedItems = this.props.segmentTransformations.get(this.props.transformationType);

                    colorMapping = this.props.colorScheme.getColorMapping(
                        transformedItems.min, transformedItems.max, 10);

                }
                segmentValues = transformedItems.values;
                segmentFeatures = segmentValues
                    .sort((s1, s2) => {
                        return s1.value > s2.value
                    })
                    .map(segment => this.toSegmentFeature(segment, colorMapping, this.props.selectedSegment))
            } else {
                if (this.props.expandedSector !== undefined) {
                    transformedItems
                        = this.props.expandedSectorTransformations.get(
                            this.props.transformationType);
                } else {
                    transformedItems = this.props.sectorTransformations.get(
                        this.props.transformationType);
                }
                sectorValues = transformedItems.values;
                colorMapping = this.props.colorScheme.getColorMapping(
                    transformedItems.min, transformedItems.max, 10);
                isochroneFeatures = sectorValues.map((sector) => {
                    return this.toSectorFeature(sector, colorMapping);
                })
            }
        } else if (this.props.sectors && this.props.sectors.length > 0) {
            sectorValues = this.props.sectors;
            isochroneFeatures = this.props.sectors.map(sector => {
                return this.toEmptySectorFeature(sector);
            });
            colorMapping = this.props.colorScheme.getColorMapping(0, 0, 0);
        } else {
            sectorValues = [];
            isochroneFeatures = [];
            colorMapping = this.props.colorScheme.getColorMapping(0, 0, 0);
        }



        const isochroneCollection = {
            "type": "FeatureCollection",
            "features": isochroneFeatures
        }

        let sectorSelectionLayer = undefined;
        if (this.props.selectionMode === SECTOR_SELECTION_MODE
            && this.props.selectedSector !== undefined) {
            const sectorIndex = this.props.selectedSector - 1;
            if (sectorIndex < sectorValues.length) {
                const sectorSelection = {
                    "type": "FeatureCollection",
                    "features": [this.toOutlineFeature(sectorValues[sectorIndex])]
                }
                sectorSelectionLayer = (
                    <Source id="sector-selector" type="geojson" data={sectorSelection}>
                        <Layer
                            id="sector-layer"
                            type="line"
                            paint={{
                                "line-width": 5
                            }}
                        />
                    </Source>
                )
            }
        }

        let sectorExpandedLayer = undefined;
        if (this.props.expandedSector !== undefined) {
            const sectorIndex = this.props.expandedSector - 1;
            if (sectorIndex < sectorValues.length) {
                const sectorSelection = {
                    "type": "FeatureCollection",
                    "features": [this.toOutlineFeature(sectorValues[sectorIndex])]
                }
                sectorExpandedLayer = (
                    <Source id="expanded-sector" type="geojson" data={sectorSelection}>
                        <Layer
                            id="expanded-sector-layer"
                            type="line"
                            paint={{
                                "line-color": "#999999",
                                "line-width": 5
                            }}
                        />
                    </Source>
                )
            }
        }

        let pointSelectionLayer = undefined;
        if (this.props.selectionMode === POINT_SELECTION_MODE && this.props.clickedLocation) {
            const pointSelection = {
                "type": "FeatureCollection",
                "features": [
                    {
                        "type": 'Feature',
                        "geometry": {
                            "type": 'Point',
                            "coordinates": this.parsePointString(this.props.clickedLocation)
                        }
                    }
                ]
            }

            pointSelectionLayer = (
                <Source id="point-selector" type="geojson" data={pointSelection}>
                    <Layer
                        id="point-selector-layer"
                        type="circle"
                        paint={{
                            "circle-radius": 5,
                            "circle-color": "#000000"
                        }}
                    />
                </Source>
            )
        }

        let centerPointFeatures = [];
        if (this.props.calculationCenterPoints.length > 0
            && this.props.calculationCenterSectors.length === 0) {
            const features = this.props.calculationCenterPoints.map(point => {
                return {
                    "type": 'Feature',
                    "geometry": {
                        "type": 'Point',
                        "coordinates": this.parsePointString(point)
                    }
                };
            });
            centerPointFeatures = centerPointFeatures.concat(features);
        }

        if (this.props.baselineCenterPoints.length > 0
            && this.props.baselineCenterSectors.length === 0) {
            const features = this.props.baselineCenterPoints.map(point => {
                return {
                    "type": 'Feature',
                    "geometry": {
                        "type": 'Point',
                        "coordinates": this.parsePointString(point)
                    }
                };
            });
            centerPointFeatures = centerPointFeatures.concat(features);
        }

        let centerPointsLayer = undefined;
        if (centerPointFeatures.length > 0) {
            const centerPointCollection = {
                "type": "FeatureCollection",
                "features": centerPointFeatures
            };

            centerPointsLayer = (
                <Source id="center-points" type="geojson" data={centerPointCollection}>
                    <Layer
                        id="center-points-layer"
                        type="circle"
                        paint={{
                            "circle-radius": 10,
                            "circle-color": "#000000"
                        }}
                    />
                </Source>
            )
        }

        let centerSectorFeatures = [];
        if (this.props.calculationCenterSectors.length > 0) {
            const features = this.props.calculationCenterSectors.map(sector => {
                const sectorIndex = sector;
                return this.toOutlineFeature(sectorValues[sectorIndex]);
            });
            centerSectorFeatures = centerSectorFeatures.concat(features);
        }

        if (this.props.baselineCenterSectors.length > 0) {
            const features = this.props.baselineCenterSectors.map(sector => {
                const sectorIndex = sector;
                return this.toOutlineFeature(sectorValues[sectorIndex]);
            });
            centerSectorFeatures = centerSectorFeatures.concat(features);
        }

        let centersSectorsLayer = undefined;
        if (centerSectorFeatures.length > 0) {
            const centerSectorsCollection = {
                "type": "FeatureCollection",
                "features": centerSectorFeatures
            }

            centersSectorsLayer = (
                <Source id="center-sectors-collection" type="geojson" data={centerSectorsCollection}>
                    <Layer
                        id="center-sectors-layer"
                        type="line"
                        paint={{
                            "line-color": "#999999",
                            "line-width": 5
                        }}
                    />
                </Source>
            );
        }

        let linesLayer = undefined
        let lineLabelsLayer = undefined;

        if (this.props.linestrings && Object.keys(this.props.linestrings).length > 0) {
            const lineFeatures = Object.entries(this.props.linestrings).map(entry => {
                const routing = entry[0];
                const routingLinestrings = entry[1];
                return routingLinestrings.map(lineString => {
                    const points = lineString.map(point => {
                        return [parseFloat(point.longitude), parseFloat(point.latitude)];
                    });

                    let description = undefined;
                    let color = "#ffcc00";

                    if (this.props.routesForRoutings && Object.keys(this.props.routesForRoutings).length > 0) {
                        description = this.props.routesForRoutings[routing].join("/");
                        color = ROUTE_COLORS[Math.abs(MurmurHash3(description).result()) % ROUTE_COLORS.length];
                    }

                    const linestringFeature = {
                        "type": "Feature",
                        "properties": {
                            "description": description,
                            "color": color
                        },
                        "geometry": {
                            "type": "LineString",
                            "coordinates": points
                        }
                    }
                    return linestringFeature;
                })
            });


            const linesCollection = {
                "type": "FeatureCollection",
                "features": lineFeatures.flat(1)
            }
            linesLayer = (
                <Source id="line-strings" type="geojson" data={linesCollection}>
                    <Layer
                        id="line-strings-layer"
                        minzoom={this.props.minRouteZoom}
                        type="line"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": ['get', 'color'],
                            'line-width': 4
                        }}
                    />
                </Source>
            );

            lineLabelsLayer = (
                <Source id="line-labels" type="geojson" data={linesCollection}>
                    <Layer
                        id="line-labels-layer"
                        type="symbol"
                        minzoom={this.props.minRouteZoom}
                        paint={{
                            'text-color': "#333333",
                        }}
                        layout={{
                            'text-size': 15,
                            "text-field": ['get', 'description']
                        }}
                    />
                </Source>
            );
        }

        let stopsLayer = undefined;
        let stopTextLayer = undefined;

        if (this.props.stops) {
            const stopFeatures = Object.entries(this.props.stops).map(stop => {
                const [stopId, stopInfo] = stop;
                return {
                    "type": "Feature",
                    "properties": {
                        "id": stopId,
                        "description": stopInfo.stopName
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": this.parsePointString(stopInfo.stopLocation)
                    }
                }
            })

            const stopCollection = {
                "type": "FeatureCollection",
                "features": stopFeatures
            }

            stopsLayer = (
                <Source id="stop-circles" type="geojson" data={stopCollection}>
                    <Layer
                        id="stop-circles-layer"
                        type="circle"
                        minzoom={14}
                        paint={{
                            "circle-radius": 3,
                            "circle-color": "#666666"
                        }}
                    />
                </Source>
            )

            stopTextLayer = (
                <Source id="stop-labels" type="geojson" data={stopCollection}>
                    <Layer
                        id="stop-labels-layer"
                        type="symbol"
                        minzoom={14}
                        layout={{
                            'text-size': 10,
                            'text-variable-anchor': ['top', 'bottom'],
                            'text-radial-offset': 0.5,
                            'text-justify': 'auto',
                            "text-field": ['get', 'description']
                        }}
                    />
                </Source>
            )
        }

        const isochroneLayer = (<Source id="isochrone-source" type="geojson" data={isochroneCollection}>
            <Layer
                id="isochrone-layer"
                type="fill"
                paint={{
                    "fill-color": ["get", "color"],
                    "fill-opacity": 0.6
                }} />
        </Source>);

        const descriptionLabelLayer = (
            <Source id="rank-labels" type="geojson" data={isochroneCollection}>
                <Layer
                    id="rank-labels-layer"
                    type="symbol"
                    minzoom={16}
                    layout={{
                        'text-size': 10,
                        'text-justify': 'auto',
                        "text-field": ['get', 'description']
                    }}
                />
            </Source>
        )



        let segmentJourneysLayer = undefined;
        if (segmentValues !== undefined && segmentValues.length > 0) {
            const features = segmentFeatures;

            const segmentJourneysCollection = {
                "type": "FeatureCollection",
                "features": features.flat(1)
            }
            segmentJourneysLayer = (
                <Source id="segment-journeys" type="geojson" data={segmentJourneysCollection}>
                    <Layer
                        id="segment-journeys-layer"
                        minzoom={this.props.minRouteZoom}
                        type="line"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": ['get', 'color'],
                            'line-width': 4
                        }}
                    />
                </Source>
            );
        }

        let hoveredSegmentPopup = undefined;
        if (this.props.hoveredSegment !== undefined && this.props.hoveredSegment.segments.length > 0) {
            const lat = this.props.hoveredSegment.point.lat;
            const lon = this.props.hoveredSegment.point.lng;
            hoveredSegmentPopup = (
                <Popup closeButton={false} closeOnClick={false} latitude={lat} longitude={lon}>
                    {this.props.hoveredSegment.segments.sort((s1, s2) => s2.value - s1.value).map((segment) => {
                        return (
                            <div key={segment.routingId + "/" + segment.sequence}>
                                <div style={{ float: "left", backgroundColor: segment.color, height: "10px", width: "10px", clear: "both", marginRight: "2px" }} />
                                <span style={{ "fontWeight": "bold" }}>{segment.label}</span> ({segment.routingBeginningStop}⇢{segment.routingEndStop} • {segment.routingStopCount} stops) <span style={{ "fontWeight": "bold" }}>{Math.floor(segment.value).toLocaleString()}</span>
                            </div>
                        );
                    })
                    }
                </Popup>
            )
        }


        const map = (
            <Map
                doubleClickZoom={false}
                visible={true}
                width="auto"
                height="auto"
                style={{ "flexGrow": 1 }}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                {...this.props.viewport}
                onMove={this.moveMap}
                onMoveEnd={this.refreshBoundaries}
                onLoad={this.refreshBoundaries}
                onClick={this.handleClick}
                onMouseMove={this.handleHover}
                mapStyle="mapbox://styles/mapbox/light-v11"
                interactiveLayerIds={["isochrone-layer", "segment-journeys-layer"]}>
                {pointSelectionLayer}
                {centerPointsLayer}
                {centersSectorsLayer}
                {sectorSelectionLayer}
                {sectorExpandedLayer}
                {linesLayer}
                {lineLabelsLayer}
                {stopsLayer}
                {stopTextLayer}
                {descriptionLabelLayer}
                {isochroneLayer}
                {segmentJourneysLayer}
                {hoveredSegmentPopup}
            </Map>
        );

        let scale;
        if (this.props.transformationType !== undefined) {
            const isComparison = this.props.isComparison;
            const isSectorExpanded = this.props.expandedSector !== undefined;
            const isExhaustive = this.props.isExhaustive;
            const isSegments = (this.props.segmentTransformations !== undefined) &&
                (this.props.sectorTransformations === undefined);
            const isSegmentExpanded = this.props.selectedSegment !== undefined;

            let transformationTypes
            if (this.props.transformationType !== undefined) {
                transformationTypes = Object.entries(getTransformationsMapForContext(
                    isSegments, isSegmentExpanded, isComparison, isSectorExpanded, isExhaustive))
            } else {
                transformationTypes = [];
            }

            scale = (
                <Scale colorMapping={colorMapping}
                    isComparison={isComparison}
                    transformationTypes={transformationTypes}
                    setTransformationType={this.props.setTransformationType}
                    transformationType={this.props.transformationType} />
            );
        } else {
            scale = null;
        }

        return (
            <>
                <RingSpinnerOverlay loading={this.props.busy} color="#000000" />
                {map}
                {scale}
            </>
        );
    }

}


export default AccessMap